import React from 'react';

const EstimatorOutput = ({ estimatorOutput }) => {
  return (
    <>
      <div className="bg-white shadow-md text-center rounded-md py-3 px-5">
        <div className="text-2xl font-bold dedalog-blue mt-5 mb-10">Estimativa</div>

        <div className="mb-14">
          <span className="text-lg block mb-2">Taxa mensal de armazenagem</span>
          <div className="text-2xl font-bold">
            R$ <span id="taxa_armazenagem">{estimatorOutput.armazenagem}</span>
          </div>
        </div>

        <div className="mb-14">
          <span className="text-lg block mb-2">Taxa de logística por pedido</span>
          <div className="text-2xl font-bold">
            R$ <span id="taxa_logistica">{estimatorOutput.logistica}</span>
          </div>
        </div>

        <div className="pb-10">
          <span className="text-lg block mb-2">Preço e prazo do frete</span>
          <div className="text-2xl font-bold mb-2">
            R$ <span id="taxa_frete_expresso">{estimatorOutput.frete}</span>
          </div>
          <div className="text-2xl font-bold">
            <span id="prazo_frete_expresso">{estimatorOutput.prazo}</span> dias
          </div>
        </div>
      </div>
    </>
  );
};

export default EstimatorOutput;
