import React, { useState } from 'react';
import Button from '../Button';
import EstimatorOutput from './EstimatorOutput';

const outputPrice = (price) => {
  return parseFloat(price).toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

const isEmailWithTLD = (email) => {
  return /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*(\.\w{2,})+$/.test(
    email
  );
};

const EstimatorLayout = () => {
  const [enableEstimator, setEnableEstimator] = useState(false);
  const [loadingEstimator, setLoadingEstimator] = useState(false);

  const [estimator, setEstimator] = useState({
    largura: 21,
    altura: 16,
    comprimento: 10,
    peso: 500,
    items_armazenagem_mensal: 500,
    items_pedido: 1,
    pedidos_mes: 100,
    cep: '01001-001',
    vNF: 50,
    email: '',
  });
  const handleChange = (event) => {
    if (event.target.name === 'email') {
      if (isEmailWithTLD(event.target.value)) {
        setEnableEstimator(true);
      } else {
        setEnableEstimator(false);
      }
    }

    const auxValues = { ...estimator };
    auxValues[event.target.name] = event.target.value;
    setEstimator(auxValues);
  };

  const [estimatorOutput, setEstimatorOutput] = useState({
    armazenagem: outputPrice(0),
    logistica: outputPrice(0),
    frete: outputPrice(0),
    prazo: 0,
  });

  const estimate = async () => {
    setLoadingEstimator(true);
    const req = await fetch('https://estimator.dedalog.com.br/estimate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'MA8Chy4iRHRNIgoJeXYR1kA0p7ben4F7oUsRGRDi',
      },
      body: JSON.stringify(estimator),
    }).catch((error) => setLoadingEstimator(false));

    const resp = await req.json();

    setEstimatorOutput({
      armazenagem: outputPrice(resp.Armazenagem),
      logistica: outputPrice(resp.Logistica),
      frete: outputPrice(resp.Expresso.preco),
      prazo: parseInt(resp.Expresso.prazo),
    });

    setLoadingEstimator(false);
  };

  return (
    <>
      <div className="mx-5 pl-5 flex-auto">
        <span className="block pb-2 font-bold text-lg dedalog-blue underline">Produto</span>

        <div className="bg-white shadow-md rounded-md pl-5 py-3">
          <label className="block mb-5">
            <span className="text-gray-700">Dimensões (cm)</span>
            <div className="flex">
              <input
                type="text"
                onChange={handleChange}
                name="largura"
                className="w-20 mt-0 px-4 text-center border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                placeholder="21"
              />
              <span className="self-center">X</span>
              <input
                type="text"
                onChange={handleChange}
                name="altura"
                className="w-20 mt-0 px-2 text-center border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                placeholder="16"
              />
              <span className="self-center">X</span>
              <input
                type="text"
                onChange={handleChange}
                name="comprimento"
                className="w-20 mt-0 px-0.5 text-center border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                placeholder="10"
              />
            </div>
          </label>

          <label className="block mb-5">
            <div className="flex flex-col sm:flex-row">
              <div className="flex-auto mr-5 mb-5 sm:mb-0">
                <span className="text-gray-700">Peso (gramas)</span>
                <input
                  type="text"
                  onChange={handleChange}
                  name="peso"
                  className="block w-20 mt-0 px-4 text-center border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="500"
                />
              </div>
              <div className="flex-auto">
                <span className="text-gray-700">Itens armazenados por mês</span>
                <input
                  type="text"
                  onChange={handleChange}
                  name="items_armazenagem_mensal"
                  className="block w-20 mt-0 px-4 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="500"
                />
              </div>
            </div>
          </label>
        </div>

        <span className="block pt-3 py-2 font-bold text-lg dedalog-blue underline">Pedidos</span>

        <div className="bg-white shadow-md rounded-md px-5 pt-3 pb-1">
          <label className="block mb-5">
            <div className="flex flex-col sm:flex-row">
              <div className="flex-auto mr-5 mb-5 sm:mb-0">
                <span className="text-gray-700">Produtos em um único pedido</span>
                <input
                  type="text"
                  onChange={handleChange}
                  name="items_pedido"
                  className="block mt-0 px-0.5 text-center border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="1"
                />
              </div>

              <div className="flex-auto">
                <span className="text-gray-700">Pedidos enviados por mês</span>
                <input
                  type="range"
                  onChange={handleChange}
                  name="pedidos_mes"
                  className="block mt-2 px-0.5 text-center border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  value={estimator.pedidos_mes}
                  min="100"
                  max="10000"
                  step="100"
                />
                <span className="ml-24" id="qty_pedidos_mes">
                  {estimator.pedidos_mes}
                </span>
              </div>
            </div>
          </label>
        </div>

        <span className="block pt-3 py-2 font-bold text-lg dedalog-blue underline">Frete</span>
        <div className="bg-white shadow-md rounded-md pl-5 py-3">
          <label className="block mb-5">
            <div className="flex">
              <div>
                <span className="text-gray-700">CEP</span>
                <input
                  type="text"
                  onChange={handleChange}
                  name="cep"
                  className="w-28 mt-0 px-4 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="01001-001"
                />
              </div>

              <div className="ml-7">
                <span className="text-gray-700">Valor NF (R$)</span>
                <input
                  type="text"
                  onChange={handleChange}
                  name="vNF"
                  className="w-28 mt-0 px-4 text-center border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="50.00"
                />
              </div>
            </div>
          </label>
        </div>

        <div className="my-3 text-center md:text-left">
          <input
            type="text"
            onChange={handleChange}
            name="email"
            className="shadow-md rounded-md w-80 mt-0 px-4 mr-4 text-center border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder="Seu email"
          />
          <Button
            onClick={() => estimate()}
            isDisabled={!enableEstimator}
            className={enableEstimator ? '' : 'cursor-not-allowed'}
            btnBackground={enableEstimator ? 'bg-red-dedalog' : 'bg-gray-500'}
          >
            {loadingEstimator ? '⌛' : 'Estimar'}
          </Button>
        </div>
      </div>
      <div className="mx-5 pl-5 flex-auto md:mt-9">
        <EstimatorOutput estimatorOutput={estimatorOutput} />
      </div>
    </>
  );
};

export default EstimatorLayout;
