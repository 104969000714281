import * as React from 'react';
import Layout from '../components/layout/Layout';
import EstimatorLayout from '../components/estimator/EstimatorLayout';

const PricingPage = ({ data }) => {
  return (
    <Layout>
      <section id="precos" className="bg-blue-200 pt-10 pb-20">
        <div className="container mx-auto text-center">
          <h3 className="text-2xl lg:text-4xl font-semibold">Taxas 100% transparentes</h3>
          <p className="pt-2">
            O fulfillment da sua loja no piloto automático: rápido, escalável e acessível
          </p>
        </div>
        <div className="container mx-auto">
          <div className="flex flex-col md:flex-row mt-8 px-2 lg:px-56">
            <EstimatorLayout />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PricingPage;
